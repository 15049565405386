import { createContext, ReactNode, useEffect, useState } from "react";
import { SurveyStatus } from "./app.constants";
import { AppContextValue, TabNames } from "./app.types";
import { useLocation, useParams } from "react-router-dom";
import { InitState, UUID } from "gx-npm-lib";

const AppContext = createContext<AppContextValue>({} as AppContextValue);
const AppContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [initId, setInitId] = useState<UUID>("");
  const [initProdId, setInitProdId] = useState<UUID>("");
  const [initState, setInitState] = useState<InitState>(InitState.ACTIVE);
  const [isHeaderDataLoaded, setIsHeaderDataLoaded] = useState<boolean>(false);
  const [isOnboardingDrawerOpen, setIsOnboardingDrawerOpen] = useState<boolean>(false);
  const [isSavingIndicator, setIsSavingIndicator] = useState<boolean>(false);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [isSurveyLegalDismissed, setIsSurveyLegalDismissed] = useState<boolean>(true);
  const [savingCount, setSavingCount] = useState<number>(0);
  const [selectedTab, setSelectedTab] = useState<TabNames | "">("");
  const [surveyHasRequestedDocs, setSurveyHasRequestedDocs] = useState<boolean>(false);
  const [surveyId, setSurveyId] = useState<UUID>("");
  const [surveyRequestedByCompanyName, setSurveyRequestedByCompanyName] = useState<string>("");
  const [surveyRequestedByEmail, setSurveyRequestedByEmail] = useState<string>("");
  const [surveyRequestedByUserName, setSurveyRequestedByUserName] = useState<string>("");
  const [surveyStatus, setSurveyStatus] = useState<SurveyStatus>(SurveyStatus.DEFAULT);
  const { initiativeId: routeInitId, initProductId: routeInitProdId } = useParams();
  const location = useLocation();
  const [isPreviewMode, setIsPreviewMode] = useState(false);

  const contextValue = {
    initId,
    initProdId,
    initState,
    isHeaderDataLoaded,
    isOnboardingDrawerOpen,
    isSavingIndicator,
    isScrolling,
    isSnackbarOpen,
    isSurveyLegalDismissed,
    isPreviewMode,
    location,
    savingCount,
    selectedTab,
    surveyId,
    surveyHasRequestedDocs,
    surveyRequestedByCompanyName,
    surveyRequestedByEmail,
    surveyRequestedByUserName,
    surveyStatus,
    setIsPreviewMode,
    setInitId,
    setInitProdId,
    setInitState,
    setIsHeaderDataLoaded,
    setIsOnboardingDrawerOpen,
    setIsSavingIndicator,
    setIsScrolling,
    setIsSnackbarOpen,
    setIsSurveyLegalDismissed,
    setSavingCount,
    setSelectedTab,
    setSurveyHasRequestedDocs,
    setSurveyId,
    setSurveyRequestedByCompanyName,
    setSurveyRequestedByEmail,
    setSurveyRequestedByUserName,
    setSurveyStatus,
  };

  useEffect(() => {
    setInitId(routeInitId as UUID);
    setInitProdId(routeInitProdId as UUID);
  }, [location, routeInitId, routeInitProdId]);

  return <AppContext.Provider value={contextValue}>{props.children}</AppContext.Provider>;
};

export { AppContext, AppContextProvider };
