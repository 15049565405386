import * as singleSpa from "single-spa";
import { useTranslation } from "react-i18next";
import styles from "./branding-header.styles";
import { makeStyles } from "@material-ui/core";
import { Button, TypographyComponent } from "gx-npm-ui";
import { ArrowBackIcon, BuySmartLogoOrangeStacked } from "gx-npm-icons";
import { colorPalette } from "gx-npm-common-styles";
import { NewAppPaths } from "../../app.constants";
import { useContext } from "react";
import { AppContext } from "../../app.context";

const useStyles = makeStyles(() => styles);

const BrandingHeader = () => {
  const classes = useStyles();
  const { isPreviewMode } = useContext(AppContext);
  const { t } = useTranslation();

  const handleClick = () => {
    singleSpa.navigateToUrl(NewAppPaths.TASKS_DASHBOARD);
  };

  return (
    <div className={classes.brandingRoot}>
      <div className={classes.brandingLeftContainer}>
        {!isPreviewMode && (
          <Button rootClassName="btn-tertiary" onClick={handleClick}>
            <ArrowBackIcon />
            <TypographyComponent styling={"p3"} color={"inherit"} boldness={"semi"}>
              {t("BACK TO MY TASKS")}
            </TypographyComponent>
          </Button>
        )}
        <div className={classes.brandingSeparator} />
      </div>
      <BuySmartLogoOrangeStacked fillbottom={colorPalette.basic.white.hex} />
    </div>
  );
};

export default BrandingHeader;
