import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../app.context";
import styles from "./closed-survey-header.styles";
import { TypographyComponent } from "gx-npm-ui";
import { InitState } from "gx-npm-lib";

const useStyles = makeStyles(() => styles);
const ClosedSurveyHeader = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { initState } = useContext(AppContext);
  return (
    <section
      aria-label={t("survey closed banner")}
      className={classNames(
        classes.closedHeaderContainer,
        [InitState.ARCHIVED, InitState.DELETED].includes(initState) && "is-closed"
      )}
    >
      <TypographyComponent
        rootClassName={classNames(classes.copyContainer)}
        styling={"p3"}
        color={"coal"}
        boldness={"medium"}
      >
        {t("This questionnaire has been closed.")}
      </TypographyComponent>
    </section>
  );
};

export default ClosedSurveyHeader;
