import { colorPalette } from "gx-npm-common-styles";

const closedSurveyHeaderStyles = {
  closedHeaderContainer: {
    background: `var(${colorPalette.accent.lightSourGrape.name})`,
    display: "none",
    height: "48px",
    "&.is-closed": {
      display: "flex",
    },
  },
  copyContainer: {
    alignItems: "center",
    display: "inline-flex",
    margin: "0px 24px 0px auto",
    order: 2,
  },
};

export default closedSurveyHeaderStyles;
