import React, { Fragment, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, Fade } from "@material-ui/core";
import { Button, Loader, TextLink, TooltipV2, TypographyComponent } from "gx-npm-ui";
import styles from "./survey-header.styles";
import ShareResponsesDialog from "./share-responses-dialog/share-responses-dialog.component";
import SurveyClipboard from "../../assets/images/survey-clipboard.svg";
import SpinningSaveIndicator from "../../ui/spinning-save-indicator/spinning-save-indicator.component";
import { SurveyHeaderContext } from "./survey-header.context";
import { AppContext } from "../../app.context";
import { SurveyStatus } from "../../app.constants";
import classNames from "classnames";
import { CustomEvents } from "../../app.types";

const useStyles = makeStyles(() => styles);

const SurveyHeader = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    isHeaderDataLoaded,
    isScrolling,
    surveyRequestedByCompanyName,
    surveyRequestedByEmail,
    surveyRequestedByUserName,
    surveyStatus,
    setSavingCount,
    isPreviewMode,
  } = useContext(AppContext);
  const { setIsShareResponsesDialogOpen } = useContext(SurveyHeaderContext);

  useEffect(() => {
    const listener = (event: CustomEvent) => {
      setSavingCount(event.detail.count);
    };
    window.addEventListener(CustomEvents.QUESTIONNAIRE_EDIT_COUNT, listener);
    return () => {
      window.removeEventListener(CustomEvents.QUESTIONNAIRE_EDIT_COUNT, listener);
    };
  }, [setSavingCount]);

  return (
    <div className={classNames(classes.surveyRoot, isScrolling && classes.surveyRootScrolling)}>
      {!isHeaderDataLoaded && <Loader />}
      {isHeaderDataLoaded && (
        <div className={classNames(classes.surveyContainer)}>
          {!isScrolling && (
            <Fade in={!isScrolling} timeout={{ enter: 600 }}>
              <div className={classes.surveyLeftContainer}>
                <img className={classes.surveyClipboardImage} src={SurveyClipboard} alt={t("survey clipboard icon")} />
                <div className={classes.surveyRequestedByTextWrapper}>
                  <TypographyComponent
                    rootClassName={classes.surveyRequestedByCompanyNameText}
                    styling={"p1"}
                    color={"carbon"}
                    boldness={"medium"}
                  >
                    {surveyRequestedByCompanyName}
                  </TypographyComponent>
                  <TypographyComponent element={"span"} styling={"p4"} color={"iron"} boldness={"medium"}>
                    {t("Requested by")}
                    {` `}
                    <TextLink
                      variant={"primary"}
                      href={`mailto:${surveyRequestedByEmail}`}
                      text={surveyRequestedByUserName}
                    />
                  </TypographyComponent>
                </div>
              </div>
            </Fade>
          )}
          <div className={classes.surveyRightContainer}>
            {isPreviewMode && !isScrolling ? (
              <TooltipV2
                title={t("The questionnaire cannot be shared in preview mode.")}
                placement="top"
                PopperProps={{ modifiers: { offset: { offset: "10px, 8px" } } }}
                rootClassName={classes.toolTipClass}
              >
                <div>
                  <Button rootClassName={classNames("btn-primary", classes.surveyResponsesShareButton)} disabled={true}>
                    {t("SHARE RESPONSES")}
                  </Button>
                </div>
              </TooltipV2>
            ) : (
              !isPreviewMode && (
                <Fragment>
                  <SpinningSaveIndicator />
                  {surveyStatus === SurveyStatus.SUBMITTED && (
                    <div className={classes.headerDataResponsesSharedContainer}>
                      <div className={classes.surveyResponsesSharedSeparator} />
                      <TooltipV2
                        title={t(
                          "Need to make a change? Updates to responses will automatically update for the evaluation team."
                        )}
                        placement="left"
                        PopperProps={{ modifiers: { offset: { offset: "0px, 12px" } } }}
                      >
                        <div>
                          <TypographyComponent styling={"p3"} color={"iron"} boldness={"medium"}>
                            {t("Responses shared")}
                          </TypographyComponent>
                        </div>
                      </TooltipV2>
                    </div>
                  )}
                  {!isScrolling && (
                    <>
                      {surveyStatus !== SurveyStatus.SUBMITTED && (
                        <TooltipV2
                          title={t(
                            "Sharing will make all responses available to the evaluation team. Once shared, you can still make changes. Any changes will be automatically be shared back with the eval team."
                          )}
                          placement="bottom"
                          PopperProps={{ modifiers: { offset: { offset: "0px, 8px" } } }}
                        >
                          <div>
                            <Button
                              rootClassName={classNames("btn-primary", classes.surveyResponsesShareButton)}
                              onClick={() => setIsShareResponsesDialogOpen(true)}
                            >
                              {t("SHARE RESPONSES")}
                            </Button>
                          </div>
                        </TooltipV2>
                      )}
                    </>
                  )}
                </Fragment>
              )
            )}
          </div>
        </div>
      )}
      <ShareResponsesDialog />
    </div>
  );
};

export default SurveyHeader;
