import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Dialog, TypographyComponent } from "gx-npm-ui";
import { AppContext } from "../../app.context";
import { useTranslation } from "react-i18next";
import VidyardEmbed from "@vidyard/embed-code";
import styles from "./onboarding-dialog.styles.module.scss";

const OnboardingDialog = () => {
  const { t } = useTranslation();
  const videoRef = useRef<HTMLDivElement | null>(null);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const { surveyRequestedByCompanyName, setIsOnboardingDrawerOpen, isOnboardingDrawerOpen } = useContext(AppContext);

  useEffect(() => {
    if (isOnboardingDrawerOpen) {
      setIsVideoReady(true);
    }
  }, [isOnboardingDrawerOpen]);

  useEffect(() => {
    if (isVideoReady && videoRef.current) {
      VidyardEmbed.api.renderPlayer({ container: videoRef.current, uuid: "7ipTRdHCn3RXhoqUka2zSA" });
    }
  }, [isVideoReady]);

  const dialogContent = (
    <div className={styles.content}>
      <div className={styles.leftContainer}>
        <div className={styles.description}>
          <TypographyComponent element="span" styling="h3" color="yam" boldness="medium">
            {surveyRequestedByCompanyName}
          </TypographyComponent>
          <TypographyComponent element="span" styling="h3" color="carbon" boldness="medium">
            {t(" is using BuySmart for a technology evaluation, and wants to know more about your product.")}
          </TypographyComponent>
          <TypographyComponent rootClassName={styles.bulletTitle} styling="p2" color="carbon" boldness="semi">
            {t("With BuySmart, you can:")}
          </TypographyComponent>
          <ul className={styles.bulletList}>
            {["Respond to questions", "Upload requested documents", "Share responses in real time"].map((text) => (
              <li key={text}>
                <TypographyComponent styling="p2" color="coal" boldness="regular">
                  {t(text)}
                </TypographyComponent>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.mediaContainer}>
          <div ref={videoRef} className={styles.media} />
        </div>
        <Button onClick={() => setIsOnboardingDrawerOpen(false)} rootClassName="btn-primary">
          {t("Got it")}
        </Button>
      </div>
    </div>
  );

  return (
    <Dialog
      rootClassName={styles.root}
      open={isOnboardingDrawerOpen}
      body={dialogContent}
      handleClose={() => setIsOnboardingDrawerOpen(false)}
      maxDialogWidth="1158px"
      height="640px"
      closeIcon
      wrapperClassName={styles.dialogContainer}
      closeIconClassName={styles.closeIcon}
    />
  );
};

export default OnboardingDialog;
